.testimonialCard {
    display: flex;
    height: 351px;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    background: #FFF;
    margin-top: 71px;
    margin-left: 30px;
    margin-right: 30px;
}

.testimonialImage {
    width: 100%;
    height: auto;
    margin-left: 30px;
}

.testimonialContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
    height: 100%;
}

.testimonialQuote {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-top: 30px;
    overflow: hidden;
}

