:root {
    --color-primary: #9825F2;
    --color-primary-dark: #161227;

    --color-complementary-purple: #6638B3;
    --color-complementary-barnie: #BE50FF;
    --color-complementary-lila: #C9A8FF;
    --color-complementary-lila_light: #E4D3FF;
    --color-complementary-grey: #E6E6E6;
    --color-complementary-white: #FFFFFF;

    --color-background-hover_grey: #F4F3FF;
    --color-background-neutral_grey: #F8F8F8;
    --color-background-pale_grey: #F4F6F9;

    --color-system-success: #30BA00;
    --color-system-alert: #FAAD14;
    --color-system-error: #E30001;
    --color-system-info: #46A9F1;
}




* {
    font-family: 'Poppins' !important;
    margin: 0;
}

.link-footer:hover{
    color: #9825f2 !important;
}
html, body{
    height: 100vh;

}

.slick-dots{
    padding-bottom: 50px;
}

.slick-active button {
    background: var(--color-primary) !important;
}

.slick-active button:hover{
    opacity: 1 !important;
}

.ant-carousel .slick-dots li {
    border-radius: 1px;
    background: #C0C0C0 !important;
}


.ant-card-body {
    height: 100%;
}


.headline {
    color: var(--color-primary-dark);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.headline-contact{
    color: var(--color-primary);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.headline-one {
    color: var(--color-primary-dark);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}


.headline-two {
    color: var(--color-primary-dark);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 
}

.headline-sb {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; 
}

.subtitle {
    color: #717171;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.subtitle-medium{
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pre-title {
    color: #343434;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pre-title-r {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-small {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}



.buttonPrimary{
    background: #9825f2;
    color: #fff;
    height: 54px;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    outline: none !important;
    text-decoration: none !important;
    border-radius: 2px;
    background: var(--Primary, #9825F2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.buttonPrimary:hover{
    color: #FFF !important;
    border-color: #9825f2 !important;
    background: var(--barnie, #BE50FF);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04) !important;
}



.button-secondary{
    height: 54px;
    border-radius: 2px;
    border: 1px solid var(--Primary, #9825F2);
    background: var(--blanco, #FFF);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    color: var(--Primary, #9825F2);
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button-secondary:hover{
    color: #FFF !important;
    border-color: var(--color-primary) !important;
    background: var(--color-primary);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04) !important;
}

.button-secondary-b{
    height: 54px;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    background: var(--blanco, #FFF);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.button-secondary-b:hover{
    border-radius: 2px;
    border: 1px solid var(--color-primary) !important;
    background: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    color: var(--color-primary) !important;
}

.ant-menu-item-selected{
    color: var(--color-primary) !important;
    background: #FFF !important;
}

.ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title{
    color: var(--color-primary) !important;
}

.ant-menu-item:hover{
    color: var(--color-primary) !important;
    background: #F9F4FF !important;
}


.ant-menu-horizontal {
    border-bottom: none !important;
}

.ant-menu-overflow-item:hover{
    background-color: #FFF !important;
}

.ant-menu-item:hover::after {
    border-bottom-color: var(--color-primary) !important;
}


.ant-menu-submenu:hover::after{
    border-bottom-color: var(--color-primary) !important;
}

.ant-menu-submenu-selected::after{
    border-bottom-color: var(--color-primary) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title{
    color: var(--color-primary) !important;
}

.ant-menu-item-selected::after{
    border-bottom-color: var(--color-primary) !important;
}

.ant-menu-submenu-active::after{
    border-bottom-color: var(--color-primary) !important;
}

.ant-collapse{
    background-color: #FFF !important;
}

.ant-card-bordered{
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}

.row-with-background{
    background-size: cover;
    background-position: center;
    /* height: 793px; */
}

.text-container{
    padding-left: 158px;
    padding-top: 140px;
}
.text-container-two{
    padding-left: 158px;
}

.image-container{
    padding-left: 97px;
    padding-right: 105px;
    padding-top: 80px;
}

.button-container{
    padding-top: 92px;
}

.companies-container{
    display: flex;
    gap: 91px;
    padding-right: 201px;
    padding-left: 61px;
    align-items: center;
}

.feature_icon_container{
    width: 95.567px;
    height: 72px;
    border-radius: 20px;
    background: #FaF9FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature_title_container{
    padding-top: 24px;
    width: 430.052px;
}

.feature_desc_container{
    width: 430.052px;
    height: 150px;
    padding-top: 8px;
}

.home-banner{
    padding-top: 124px;
    padding-right: 228px;
    padding-bottom: 99px;
}

.home-banner-title{
    padding-top: 176px;
    padding-left: 158px;
    padding-right: 40px;
}

.home-button-banner{
    padding-top: 81px;
}

.home-items-container{
    text-align: center;
    padding: 128px 483px 103px 483px;
}

.pcarga-container{
    padding: 116px 127px 0px 158px;
}

.pcarga-image{
    padding: 69px 156px 0px 0px
}

.invertir-col{
    order: 1;
}

.container-img-left{
    padding: 154px 0px 0px 157px;
}

.container-text-right{
    padding: 150px 158px 0px 204px;
}

.pp-text{
    padding: 150px 158px 0px 204px;
}

.log-container{
    padding: 255px 127px 0px 158px;
}

.log-image{
    padding: 99px 158px 0px 0px;
}

.pri-container{
    padding: 72px 226px 0px 158px;
}

.pri-image{
    padding: 70px 158px 0px 0px;
}

.whatsapp-container{
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    position: fixed;
    right: 0;
    bottom: 96px;
    z-index: 1000;
    cursor: pointer;
}

.contact-container{
    display: none;
}

.whatsapp-container:hover{
    bottom: 100px;
}

.ant-form-item{
    margin-bottom: 0px;
}



/* footer */

.footer-container-left{
    padding-left: 108px;
    padding-top: 107px;
    padding-bottom: 184px;
}

.footer-container-product{
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding-top: 115px;
    padding-left: 50px
}

.footer-container-company{
    padding-right: 108px;
    padding-top: 115px;
}

.item-about{
    text-align: start;
}

.footer-container-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 49px 108px 10px 108px;
}

.footer-item-linkedin{
    display: flex;
    align-items: center;
    gap: 49px;
}

.pp-image{
    height: 741px;
}

.feature-title{
    text-align: center;
    padding-top: 236px;
}

.line-partners{
    width: 1px;
    height: 109px;
    background: #D9D9D9;
}

.testimonial-quote{
    padding-top: 33px;
    height: 167px;
}

.htitle{
    height: 793px;
}

.pri-row{
    margin-top: 223px;
}

.nav-menu{
    width: 100%;
    justify-content: flex-end;
    padding-right: 52px;

}

.btn-header{
    margin-right: 11px;
}

.testimonial-container{
    padding-left: 30px;
    padding-right: 30px;
}

.icon-arrow{
    padding-left: 8px;
}


/* pricing */

.main-title-container{
    margin-top: 46px;
    padding-left: 158px;
}

.title-item{
    margin-top: 76px;
}

.button-item{
    display: flex;
    gap: 16px;
    margin-top: 60px;
}

.main-image-container{
    padding: 112px 158px 140px 88px;
}

.main-manage-title{
    margin-top: 80px;
}

.row-with-background-pri{
    background-size: cover;
    background-position: 0px 6px;
    height: 590px;
    margin-top: 143px;
}

.manage-text-container{
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding-left: 158px;
    padding-top: 126px;
}

.manage-image-container{
    padding: 90px 158px 310px 180px
}

.manage-image-container-two{
    padding-top: 142px;
    padding-left: 158px;
    padding-bottom: 174px;
}

.manage-text-container-two{
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding: 142px 158px 492px 103px;
}

.manage-text-container-three{
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding-left: 158px;
    padding-top: 150px;
}

.manage-image-container-three{
    padding: 135px 158px 0px 100px
}

.manage-text-container-four{
    display: flex;
    flex-direction: column;
    gap: 31px; 
    padding: 238px 158px 492px 83px;
}

.manage-image-container-four{
    padding-top: 191px;
    padding-left: 158px;
}

.banner-pri{
    padding-top: 243px;
    padding-left: 158px;
    padding-right: 40px;
}

.banner-button{
    padding-top: 127px;
    padding-bottom: 100px;
}

.banner-image{
    padding-top: 124px;
    padding-right: 228px;
    padding-bottom: 99px;
    padding-left: 155px;
}

.container-two-title{
    padding-right: 0px;
}

.form-container-pri{
    padding-top: 153px;
    padding-bottom: 200px;
    margin-bottom: 164px;
}

.row-container-button{
    margin-bottom: 35px;
    padding-top: 51px;
}

.btn-form{
    width: 155px; 
    margin-right: 49px;
}

.row-form{
    gap: 41px;
    margin-top: 74px;
    padding-left: 55px;
    padding-right: 55px;
}

/* fin pricing */

/* planners */

.planner-text-container{
    padding-top: 126px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 158px;
}

.planners-image-container{
    padding-right: 158px;
    padding-top: 113px;
    padding-left: 99px;
}

.planners-feature-item{
    display: flex;
    flex-direction: column;
    gap: 39px;
    padding-top: 48px;
}

.planners-image-container-two{
    padding: 220px 103px 0px 158px;
}

.planners-text-container-two{
    display: flex;
    flex-direction: column;
    padding-top: 257px;
    padding-right: 158px;
    padding-bottom: 281px;
    padding-left: 103px;
    gap: 82px;
}

.planners-feature-item-two{
    display: flex;
    flex-direction: column;
    gap: 39px;
}

.planners-text-container-three{
    display: flex;
    flex-direction: column;
    gap: 93px;
    padding: 209px 0px 0px 158px;
}

.planners-feature-item-three{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.planners-image-container-three{
    padding-left: 260px;
    padding-right: 383px;
    padding-top: 128px;
}

.banner-pla{
    padding-top: 176px;
    padding-left: 158px;
    padding-right: 40px;
}

.banner-button-pla{
    padding-top: 71px;
    padding-bottom: 100px;
}

.banner-image-pla{
    padding-top: 133px;
    padding-right: 158px;
}

.row-banner{
    background: #FAF9FF;
    margin-top: 100px;
}

/* fin planners */

/* planners carga */

.pcarga-text-container{
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding: 126px 180px 0px 158px;
}

.pcarga-image-container{
    padding-top: 83px;
    padding-right: 158px;
}

.pcarga-text-container-two{
    display: flex;
    flex-direction: column;
    padding-top: 166px;
    padding-left: 96px;
    padding-right: 158px;
    padding-bottom: 223px;
}

.pcarga-image-container-two{
    padding-top: 105px;
}

.pcarga-feature-item{
    display: flex;
    flex-direction: column;
    gap: 47px;
    padding-top: 59px;
}

.pcarga-text-container-three{
    padding: 65px 0px 70px 158px;
}

.pcarga-tree-title{
    display: flex;
    flex-direction: column;
    gap: 31px;
}

.pcarga-tree-collapse{
    padding-top: 47px;
}

.pcarga-image-container-three{
    padding: 80px 158px 0px 55px;
}

.pcarga-image-container-four{
    padding-left: 256px;
    padding-right: 300px;
    padding-top: 120px;
}

.pcarga-text-container-four{
    display: flex;
    flex-direction: column;
    gap: 125px;
    padding-top: 142px;
    padding-right: 158px;
}

.container-four-title{
    display: flex;
    flex-direction: column;
    gap: 31px;
}

.feature-item-four{
    display: flex;
    flex-direction: column;
    gap: 39px;
}
/* fin planners carga */

/* consultoria */

.co-text-container{
    padding: 46px 158px 0px 165px;
}

.co-feature-container{
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding-top: 74px;
    padding-bottom: 64px;
}

.co-image-container{

}

.co-image-container-mobile{
    display: none;
}

.co-text-container-two{
    padding-left: 158px;
}

.co-feature-container-two{
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding-top: 74px;
    padding-bottom: 64px;
}

.co-image-container-two{
    padding: 0px 158px 0px 116px;
}

.co-text-container-two h3{
    padding-right: 54px;
}

.co-image-container-three{
    padding-top: 255px;
    padding-left: 158px;
}

.co-text-container-three{
    padding-top: 316px;
    padding-right: 158px;
    padding-left: 161px;
}

.co-feature-container-three{
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding-top: 74px;
    padding-bottom: 64px;
}

.co-text-container-four{
    padding-left: 158px;
    padding-top: 28px;
}

.co-feature-container-four{
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding-top: 119px;
    padding-bottom: 51px;
}

.co-image-container-four{
    padding-left: 116px;
    padding-right: 158px;
}

.co-text-container-five{
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-top: 316px;
    padding-right: 215px;
    padding-left: 161px;
}

.co-text-container-six{
    padding-left: 158px;
    padding-top: 233px;
    padding-bottom: 96px;
}

.co-feature-container-five{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 74px;
    padding-bottom: 64px;
}

.co-image-container-five{
    padding-left: 90px;
    padding-bottom: 155px;
}

.co-image-container-five-mobile{
    display: none;
}

.banner-co{
    display: flex;
    flex-direction: column;
    gap: 120px;
    align-items: center;
    padding: 174px 200px 0px 200px;
}

.banner-title{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.banner-button-co{
    padding-bottom: 167px;
}


/* fin consultoria */


/* nosotros */

.n-image-container{
    padding-left: 433px;
    padding-right: 432px;
    padding-top: 109px;
}

.n-text-container{
    padding-left: 158px;
    padding-right: 158px;
    padding-top: 124px;
}

.n-feature-container{
    display: flex;
    flex-direction: column;
    gap: 97px;
    padding-top: 239px;
    padding-right: 176px;
}

.n-image-container-two{
    padding-top: 212px;
    padding-left: 161px;
    padding-right: 130px;
}

.n-feature-container-two{
    display: flex;
    flex-direction: column;
    gap: 81px;
    padding-top: 227px;
    padding-left: 158px;
}

.n-image-container-three{
    padding-right: 162px;
    padding-left: 66px;
}

.n-feature-container-three{
    display: flex;
    flex-direction: column;
    gap: 84px;
    padding-top: 169px;
    padding-right: 155px;
}

.about-container{
    display: flex;
    flex-direction: column;
    padding-top: 174px;
    gap: 52px;
}

.about-data-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 41px;
    padding: 71px 158px 131px 158px;
}

.icon_container{
    width: 101px;
    height: 101px;
    margin: 0 auto;
    background-color: #FAF9FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.description_container{
    width: 396px;
    height: 152px;
    display: flex;
    align-items: center;
}

.team-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 133px;
}

.n-carousel-container{
    padding: 86px 146px 0 146px;
}

.n-carousel-item{
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
}

.team-text{
    color: #999;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
}
/* fin nosotros */

/* terminos */

.term-container{
    padding: 232px 158px 100px 158px;
}

/* fin terminos */

/* contacto */

.contact-form-container{
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 154px;
    margin-bottom: 118px;
    margin-top: 96px;
}

.banner-image-contact{
    padding-top: 124px;
    padding-right: 158px;
    padding-bottom: 99px;
}

.banner-contact{
    padding: 168px 40px 0px 158px;
}

.banner-button-contact{
    padding-top: 59px;
    padding-bottom: 89px;
}

.contact-title-container{
    text-align: center;
    padding-left: 316px;
    padding-right: 316px;
    padding-top: 80px;
}

.n-video-d{
    max-width: 100%; 
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.n-video-m{
    display: none;
    max-width: 100%; 
    width: 100%;
    overflow: hidden;
}

.n-main-title{
    padding-top: 92px;
    margin: 0 auto;
}


.time-line{
    position: absolute;
    bottom: 20px;
    left: -60px;
}

.time-line-container{
    margin-top: 250px;
    justify-content: center; 
}

@media (max-width: 767px) {
    .row-with-background {
        background-image: none !important;
        height: auto !important;
        margin-top: 0px !important;
        
    }

    /* test */

    .ant-menu-vertical{
        padding-left: 19px;
        position: absolute;
        top: 89px;
        right: 4px;
    }

    .ant-layout-header{
        padding-left: 24px;
        padding-right: 8px;
    }

    .nav-menu{
        width: 25px;
        padding-right: 0px;
    }
    
    .contact-container{
        display: flex;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1000;
        cursor: pointer;
        padding-right: 125px;
        padding-bottom: 5px;
    }

    .icon-arrow{
        display: none;
    }

    .btn-header{
        display: none;
    }

    .headline-contact{
        font-size: 25px;
    }
    

    .headline {
        font-size: 20px;

    }

    .subtitle {
        font-size: 16px;
    }

    .pre-title{
        font-size: 14px;
    }

    .pre-title-r{
        font-size: 16px;
    }

    .subtitle-medium{
        font-size: 18px;
    }

    .text-container{
        padding-top: 84px;
        padding-left: 39px;
        padding-right: 39px;
        text-align: center;
    }

    .image-container{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 52px;
    }

    .button-container{
        display: none;
    }

    .headline-sb{
        font-size: 20px;
    }

    .text-container-two{
        margin: 0 auto;
        padding-left: 0px;
        text-align: center;
        
    }

    .companies-container{
        flex-wrap: wrap;
        padding-right: 40px;
        padding-left: 40px;
        gap: 20px;
        justify-content: center;
        padding-top: 35px;
    }

    .feature_icon_container{
        width: 70.887px;
        height: 49.352px;
        flex-shrink: 0;
    }

    .feature_title_container{
        width: 318.99px;
        padding-top: 16px;
    }

    .feature_desc_container{
        width: 318px;
        height: 150px;
    }

    .home-banner{
        display: none;
    }

    .home-banner-title{
        padding-top: 63px;
        padding-left: 40px;
        padding-bottom: 63px;
        padding-right: 40px;
        text-align: center;
    }

    .whatsapp-container{
        width: 64px;
        bottom: 0;
    }

    .whatsapp-container:hover{
        bottom: 5px;
}

    .home-button-banner{
        padding-top: 51px;
    }

    .home-items-container{
        padding: 98px 24px 0px 24px
    }

    .pcarga-container{
        padding: 79px 24px 0px 24px;
    }

    .pcarga-image{
        padding: 42px 24px 0px 24px
    }

    .inv-col{
        order: 2;
    }

    .container-text-right{
        padding: 155px 24px 0px 24px;
    }

    .pp-text{
        padding: 136px 24px 0px 24px;
    }

    .container-img-left{
        padding: 47px 24px 0px 24px;
    }

    .log-container{
        padding: 136px 24px 0px 24px;
    }
    
    .log-image{
        padding: 47px 24px 0px 24px;
    }

    .pri-container{
        padding: 155px 24px 0px 24px;
    }
    
    .pri-image{
        padding: 56px 24px 0px 24px;
    }

    .ant-layout-footer{
        padding-left: 25px;
        padding-right: 25px;
    }

    .footer-container-left{
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 76px;
    }

    .footer-container-product{
        padding-top: 0px;
        padding-left: 0px
    }

    .item-about{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 54px;
    }

    .footer-container-company{
        padding-right: 108px;
        padding-top: 54px;
        padding-bottom: 67px;
    }

    .footer-container-bottom{
        display: flex;
        flex-direction: column-reverse;
        gap: 43px;
        align-items: center;
        padding: 24px 0px 17px 0px;
        text-align: center;
    }

    .footer-item-linkedin{
        flex-direction: column;
        gap: 5px;
    }

    .feature-title{
        padding-top: 120px;
    }

    .line-partners{
        display: none;
    }

    .testimonial-quote{
        height: 262px;
    }

    .pp-image{
        height: auto;
    }

    .htitle{
        height: auto;
    }
    .pri-row{
        margin-top: 0px;
    }

    .testimonial-container{
        padding-left: 5px;
        padding-right: 5px;
    }


    /* pricing */

    .main-title-container{
        margin-top: 25px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .title-item{
        margin-top: 36px;
        text-align: center;
    }

    .button-item{
        margin-top: 55px;
        justify-content: center;
    }

    .button-item a{
        display: none;
    }

    .main-image-container{
        padding: 93px 24px 0px 24px;
    }

    .main-manage-title{
        margin-top: 127px;
        padding: 0 24px
    }

    .row-with-background-pri{
        background-image: none !important;
        margin-top: 106px;
    }

    .manage-text-container{
        gap: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 0px;
    }

    .manage-image-container{
        padding: 91px 24px 0px 24px !important;
    }

    .manage-image-container-two{
        padding-top: 73px !important;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 0px;
    }

    .manage-text-container-two{
        gap: 24px;
        padding: 200px 24px 0px 24px;
    }

    .manage-text-container-three{
        gap: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 190px;
    }
    
    .manage-image-container-three{
        padding: 73px 24px 0px 24px;
    }

    .manage-text-container-four{
        gap: 24px; 
        padding: 190px 24px 0px 24px;
    }
    
    .manage-image-container-four{
        padding-top: 63px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 116px;
    }

    .banner-pri{
        padding-top: 63px;
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
    }

    .banner-button{
        padding-top: 28px;
        padding-bottom: 86px;
    }

    .banner-image{
        display: none;
    }

    .container-two-title{
        padding-right: 56px;
    }

    .row-container-button{
        justify-content: center;
    }

    .btn-form{
        margin-right: 0px;
    }

    .row-form{
        gap: 27px;
        margin-top: 40px;
        padding-left: 31px;
        padding-right: 31px;
    }

    .operation-container{
        height: 100px;
    }

    /* fin pricing */

    /* form */

    .headline-one {
        font-size: 20px;
    }
    

    .form-container-pri{
        padding: 87px 24px 91px 24px;
        
    }

    /* fin form */

    /* planners */

    .planner-text-container{
        gap: 12px;
        padding-top: 94px;
        padding-left: 24px;
        padding-right: 24px;
    }
    
    .planners-image-container{
        padding-right: 24px;
        padding-top: 84px;
        padding-left: 24px;
    }

    .planners-feature-item{
        display: flex;
        flex-direction: column;
        gap: 31px;
        padding-top: 32px;
    }

    .planners-image-container-two{
        padding: 84px 24px 0px 24px;
    }
    
    .planners-text-container-two{
        padding-top: 156px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 24px;
        gap: 44px;
    }
    
    .planners-feature-item-two{
        display: flex;
        flex-direction: column;
        gap: 39px;
    }

    .planners-text-container-three{
        gap: 44px;
        padding: 156px 24px 0px 24px;
    }
    
    .planners-feature-item-three{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .planners-image-container-three{
        padding-left: 85px;
        padding-right: 85px;
        padding-top: 84px;
    }

    .banner-pla{
        padding-top: 63px;
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
    }
    
    .banner-button-pla{
        padding-top: 36px;
        padding-bottom: 78px;
        text-align: center;
    }
    
    .banner-image-pla{
        display: none;
    }

    .row-banner{
        margin-top: 81px;
    }
    /* fin planners */

    /* planners carga */

    .pcarga-text-container{
        gap: 24px;
        padding: 95px 24px 0px 24px;
    }
    
    .pcarga-image-container{
        padding: 54px 24px 0px 24px;
    }

    .pcarga-text-container-two{
        padding: 164px 24px 0px 24px;
    }
    
    .pcarga-image-container-two{
        padding-top: 45px;
    }

    .pcarga-feature-item{
        gap: 31px;
        padding-top: 56px;
    }

    .pcarga-text-container-three{
        padding: 143px 24px 0px 24px;
    }

    .pcarga-tree-title{
        gap: 24px;
    }

    .pcarga-tree-collapse{
        padding-top: 40px;
    }

    .pcarga-image-container-three{
        padding: 67px 24px 0px 24px;
    }

    .pcarga-image-container-four{
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 89px;
    }

    .pcarga-text-container-four{
        gap: 55px;
        padding-top: 179px;
        padding-right: 24px;
        padding-left: 24px;
    }
    
    .container-four-title{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    
    .feature-item-four{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    /* fin planners carga */

    /* consultoria */

    .co-text-container{
        padding: 46px 24px 0px 24px;
    }
    
    .co-feature-container{
        display: flex;
        flex-direction: column;
        gap: 31px;
        padding-top: 54px;
        padding-bottom: 50px;
    }

    .co-image-container{
        display: none;
    }
    
    .co-image-container-mobile{
        display: flex;
        padding: 91px 24px 0px 24px
    }

    .co-text-container-two{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 186px;
    }
    
    .co-feature-container-two{
        display: flex;
        flex-direction: column;
        gap: 31px;
        padding-top: 40px;
        padding-bottom: 61px;
    }

    .co-image-container-two{
        padding: 93px 24px 0px 24px;
    }

    .co-text-container-two h3{
        padding-right: 0px;
    }

    .co-image-container-three{
        padding-top: 93px;
        padding-left: 24px;
        padding-right: 24px;
    }
    
    .co-text-container-three{
        padding-top: 186px;
        padding-right: 24px;
        padding-left: 24px;
    }
    
    .co-feature-container-three{
        gap: 16px;
        padding-top: 40px;
        padding-bottom: 48px;
    }

    .co-text-container-four{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 186px;
    }

    .co-feature-container-four{
        gap: 40px;
        padding-top: 37px;
        padding-bottom: 60px;
    }
    
    .co-image-container-four{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 93px;
    }

    .co-text-container-five{
        gap: 37px;
        padding-top: 186px;
        padding-right: 24px;
        padding-left: 24px;
    }

    .co-text-container-six{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 186px;
        padding-bottom: 0px;
    }
    
    .co-feature-container-five{
        gap: 16px;
        padding-top: 61px;
        padding-bottom: 61px;
    }
    
    .co-image-container-five-mobile{
        display: flex;
        padding-top: 61px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 80px;
    }

    .co-image-container-five{
        display: none;
    }

    .banner-co{
        gap: 38px;
        align-items: center;
        padding: 57px 24px 0px 24px;
    }
    
    
    .banner-button-co{
        padding-bottom: 57px;
    }
    
    /* fin consultoria */

    /* nosotros */

    .n-image-container{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 69px;
    }

    .n-text-container{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 86px;
    }

    .n-feature-container{
        gap: 24px;
        padding-top: 110px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .n-image-container-two{
        padding-top: 93px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .n-feature-container-two{
        gap: 24px;
        padding-top: 168px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .n-image-container-three{
        padding-top: 93px;
        padding-right: 24px;
        padding-left: 24px;
    }

    .n-feature-container-three{
        gap: 24px;
        padding-top: 167px;
        padding-right: 24px;
        padding-left: 24px;
    }

    .about-container{
        padding-top: 78px;
        gap: 50px;
    }

    .about-data-container{

        gap: 46px;
        padding: 66px 24px 82px 24px;
    }

    .description_container{
        width: auto;
    }

    .team-container{
        padding-top: 111px;
    }

    .n-carousel-container{
        padding: 61px 1px 0 1px;
    }

    
    .team-text{
        color: #999;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 45px; 
    }

    /* termns */

    .term-container{
        padding: 200px 24px 100px 24px;
    }

    /* contacto */

    .contact-form-container{
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .banner-image-contact{
        display: none;
    }

    .banner-contact{
        padding: 79px 40px 0px 40px;
        text-align: center;
    }

    .contact-title-container{
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 48px;
    }

    .ant-menu-submenu-selected::after{
        border-bottom: none !important
    }

    .n-video-d{
        display: none;
    }

    .n-video-m{
        display: flex;
    }

    .n-main-title{
        padding-left: 39px;
        padding-right: 39px;
    }
    .time-line{
        display: none;
    }

    .time-line-container{
        margin-top: 100px;
        justify-content: center; 
    }



}

@media (min-width: 768px) and (max-width: 991px){

}

@media (min-width: 992px) and (max-width: 1367px){


    .image-container{
        padding-top: 120px;
    }

    .companies-container{
        padding-right: 158px;
        gap: 36px;
    }

    .home-banner-title{
        padding-bottom: 129px;
    }

    .home-banner{
        padding-right: 158px;
    }

    .home-items-container{
        padding-left: 158px;
        padding-right: 158px;
    }

    .pcarga-container{
        padding-right: 0px;
    }

    .pcarga-image{
        padding-left: 59px;
    }

    .container-text-right{
        padding-left: 56px;
    }

    .container-img-left{
        padding-right: 56px;
    }

    .pp-image{
        height: 713px;
    }

    .pri-container{
        padding-right: 0px;
    }

    .pri-image{
        padding-left: 56px;
    }

    .footer-container-product{
        padding-left: 100px;
    }

    .footer-container-bottom{
        padding: 49px 0px 10px 0px;
    }
}
